
  .timer {
    font-size: 24px;
    font-weight: bold;
    /* margin-bottom: 32px; */
    text-align: center;
  }
  .tableContent{
  width: calc(100% - 40px);
  position: relative;
  }
  
  table {
    display: block;
    border-collapse: collapse;
    width: 100%;
    max-width: 1100px;
    /* width: 80%; */
    margin: 16px auto;
    overflow: scroll;
  }
  th,
  td {
    border: 1px solid #ddd;
    padding:5px 1px;
    text-align: center;
    white-space: nowrap; /* add this property to prevent line breaks */
  }
  
  th {
    /* background-color: #f2f2f2; */
    background-color: #EBDDF2;
    color: #333333;
    font-weight: bold;
    width: 60px;
     /* set the width of each th element */
    text-align: center !important;
  }
  .col-title{
    width: 0.2%;
    background-color: #EBDDF2;
    color: #333333;
    font-weight: bold;
  }
  
  .operator{
    width: 0.2%;
  }
  td {
    width: 1%; /* set the width of each td element to 1% of the table width */
  }

  .right-answer {
    background-color: #b0c92378;
  }
  
  .wrong-answer {
    background-color: #c9236159;
  }
  .answer-input {
    transition: background-color 0.3s ease;
  }
  
  input[type="number"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
    width: 100%;
    padding:5px 1px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    text-align: center;
  }

  input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
  
.pageBtn{
  width: 120px;
  margin: 10px;
  padding: 7px;
  font-size: 18px;
  background-color: #23C978;
  color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
}

.submitBtn{
  width: 200px;
  margin: 20px;
  padding: 10px;
  font-size: 18px;
  background-color: #E15C83;
  color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
}


.countdown img{
width: 67%;
}

.countdown h2{
  font-size: 24px;text-align: center;
}

.timer-green{background-color: #03BFA6;}
.timer-yellow{background-color: #E1B12C;}
.timer-pink{background-color: #E2A081;}
.timer-purple{background-color: #A672B0;}

.timer-box {
  display: flex; align-items: center;justify-content: center;flex-wrap: wrap;
  width: 120px;height: 120px;
  border-radius: 5px;
  margin: 0 5px;
}

.timer-box span{
  display: block;
  width: 100%; text-align: center;
  color: #fff;
}

.timer-box span:nth-child(1){
  font-weight: 700;font-size: 40px;
}
.timer-box span:nth-child(2){
  font-weight: 400;
}

.results-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  margin-left: 20px;
}

.result-box {
  width: 200px;
   background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: Arial, sans-serif;
}

.result-box h4{
  background-color: #EBDDF2;
  padding:10px 15px;
  border-radius: 8px 8px 0 0;
}


.result-box p{
  padding: 18px;
}

.table-2{
  width: 40%;float: left; margin: 20px;
}


/* Quiz.css */
@media print {
  body * {
      visibility: hidden;
  }

  .print-section, 
  .print-section * {
      visibility: visible;
  }

  .print-page {
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
  }

  .page-break {
      page-break-before: always;
  }

  table { 
      width: 100%; 
      border-collapse: collapse; 
  }

  td { 
      padding: 10px; 
      border: 1px solid black; 
  }

  input { 
      border: 1px solid black; 
      padding: 5px;
      width: 100%;
  }

  button { 
      display: none; 
  }
}


.printBtn{
  border: none;
  padding:10px 20px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  border-radius: 30px;
  margin: 0 20px;
  cursor: pointer;


}

.margin-b-32{
  margin-bottom: 32px;
}
